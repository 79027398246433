import { AppointmentStatus } from "../types/appointments";
import { Qualification } from "../types/clinicalPractitioners";
import { PatientValidationField } from "../types/validation";
import objectMap from "../utilities/objectMap";
import { splitByCapitalLetters } from "../utilities/splitByCapitalLetters";
import * as enums from "./__enums";

import { useFlag } from "../featureFlags";
import { TOGGLE_ENABLE_MESSAGE_DOCTOR, TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY } from "../featureFlags/flags";

export * from "./__enums";

export const patientValidationColumns: PatientValidationField[] = [
  "firstName",
  "lastName",
  "dateOfBirth",
  "email",
  "addressLine1",
  "addressLine2",
  "city",
  "postCode",
  "membershipNumber",
];

export const invalidFileExtensions = [
  "exe",
  "dll",
  "php",
  "cs",
  "js",
  "bin",
  "aspx",
];

export const notAppendableOutputs = ["TreatmentPlan"];

export const consultationOutputChoices = enums.consultationOutputEnum.map(
  choice => ({
    id: choice,
    name: splitByCapitalLetters(choice),
  })
);

export const consultationOutputAppendableChoices = enums.consultationOutputEnum
  .filter(choice => !notAppendableOutputs.includes(choice))
  .map(choice => ({
    id: choice,
    name: splitByCapitalLetters(choice),
  }));

export const consultationOutputTagChoices = enums.consultationOutputTagEnum.map(
  choice => ({
    id: choice,
    name: splitByCapitalLetters(choice)
  })
)

export const consultationFollowUpActionChoices =
  enums.consultationFollowUpActionEnum.map(action => ({
    id: action,
    name: splitByCapitalLetters(action),
  }));

export const termsTypeChoices = enums.termsTypeEnum.map(term => ({
  id: term,
  name: splitByCapitalLetters(term),
}));

export const stripePaymentModeChoices = enums.stripePaymentMode.map(mode => ({
  id: mode,
  name: splitByCapitalLetters(mode),
}));

export const instructionTypeChoices = enums.instructionTypesEnum.map(
  instruction => ({ id: instruction, name: instruction })
);

export const complianceLevels = enums.extraCompliance.map(level => ({
  id: level,
  name: level,
}));

export const shiftStatusChoices = enums.shiftStatusEnum.map(status => ({
  id: status,
  name: status,
}));

const generateNameByStatus = (status: AppointmentStatus) => {
  if (status === "Nonworking") {
    return {
      name: "Break",
      id: status,
    };
  }

  return {
    name: splitByCapitalLetters(status),
    id: status,
  };
};

export const schedulerAppointmentStatusChoices =
  enums
    .appointmentStatusEnum
    .filter((x: any) => x !== 'Cancelled')
    .map(generateNameByStatus);

export const patientValidationChoices: {
  id: PatientValidationField;
  name: string;
}[] = [
    { id: "firstName", name: "First name" },
    { id: "lastName", name: "Last name" },
    { id: "dateOfBirth", name: "Date of birth" },
    { id: "email", name: "Email" },
    { id: "addressLine1", name: "Address Line One" },
    { id: "addressLine2", name: "Address Line Two" },
    { id: "city", name: "City" },
    { id: "postCode", name: "Post code" },
    { id: "membershipNumber", name: "Membership number" },
  ];

export const contractQualificationChoices: {
  id: Qualification;
  name: string;
}[] = [
    {
      id: "imcgpRequired",
      name: "IMC (General Practitioner)",
    },
    {
      id: "gmcgpRequired",
      name: "GMC (General Practitioner)",
    },
    {
      id: "bacpRequired",
      name: "BACP (Counsellor)",
    },
    {
      id: "icapRequired",
      name: "ICAP (Counsellor)",
    },
    {
      id: "hcpcRequired",
      name: "HCPC (Physiotherapist)",
    },
    {
      id: "coruRequired",
      name: "CORU (Physiotherapist)",
    },
  ];

export const contractQualificationLabelMap =
  contractQualificationChoices.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: curr.name,
    }),
    {} as Record<Qualification, string>
  );

export function ConsultationTypeChoices(forCreation: boolean){
  const messageDoctorEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR);
  const messageDoctorCreateReplyEnabled = useFlag(TOGGLE_ENABLE_MESSAGE_DOCTOR_CREATE_REPLY);
  
  const shouldMessageDoctorDisplay: boolean = forCreation
    ? messageDoctorEnabled && messageDoctorCreateReplyEnabled
    : messageDoctorEnabled;

  return [
    { id: "Phone", name: "Phone" },
    { id: "Video", name: "Video" },
    shouldMessageDoctorDisplay ? { id: "MessageDoctor", name: "Message a Doctor" }: null,
    { id: "Onsite", name: "Onsite" },
  ];
}

export const bookingConsultationTypeChoices = [
  { id: "Phone", name: "Phone" },
  { id: "Video", name: "Video" },
  { id: "Onsite", name: "Onsite" },
];

export const cpConsultationPreferences = [
  { id: "Phone", name: "Phone" },
  { id: "Video", name: "Video" },
  { id: "Chat", name: "Chat" },
  { id: "Onsite", name: "Onsite" },
];

export const specialtyChoices = enums.specialtiesEnum
  .filter(x => x !== "Unknown" && x !== "CallHandler")
  .map(specialty => {
    return {
      name: splitByCapitalLetters(specialty),
      id: specialty,
    };
  });

export const identityVerificationResetStatusesChoices = (currentStatus: string) => ([
  {
    id: currentStatus,
    name: splitByCapitalLetters(currentStatus),
  },
  {
    id: "NotStarted",
    name: splitByCapitalLetters("NotStarted"),
  }
])

export const identityVerificationResetStatuses = ["Verified", "Blocked"];

export const resourceRoles = [
  {
    name: "CSO",
    id: "cso",
  },
  {
    name: "Admin",
    id: "admin",
  },
];

export const holidayCalendarMap: Record<number, string> = {
  1: "England and Wales Bank Holidays",
  2: "Scotland Bank Holidays",
  3: "Northern Ireland Bank Holidays",
};

export const holidayCalendarChoices = [
  {
    name: "England and Wales Bank Holidays",
    id: 1,
  },
  {
    name: "Scotland Bank Holidays",
    id: 2,
  },
  {
    name: "Northern Ireland Bank Holidays",
    id: 3,
  },
];

export const genderChoices = [
  { id: "Male", name: "Male" },
  { id: "Female", name: "Female" },
  { id: "Other", name: "Non Binary" },
];

export const clinicalServiceDeliveryTypes = [
  { id: "Synchronous", name: "Synchronous" },
  { id: "Asynchronous", name: "Asynchronous" },
];

export const externalValidationChoices = [
  {
    id: "wpa",
    name: "WPA",
  },
];

export const messageTypeResponse = [
  {
    name: "Reject",
    id: "reject",
  },
  {
    name: "Confirm",
    id: "confirm",
  },
];

const capsDelimitedNotificationTypes = {
  CPUserCreated: "CP User Created",
  MessageDoctorCPAnswered: "Message Doctor CP Answered",
};

const excludedNotificationTypes = [
  "BookingResendJoiningInformation",
  "BookingCreatedGP",
  "BookingCancelledGP",
  "ConsultationReminderGP"
];

const notificationTypes = enums.notificationTypeEnum.filter(
  type => !excludedNotificationTypes.includes(type)
);

const setSplitByCapitalLetters = (val: string) => ({
  [val]: splitByCapitalLetters(val),
});

const notificationTitles = objectMap(
  notificationTypes,
  setSplitByCapitalLetters
);

export const notificationTypeNames: Record<string, string> = {
  ...notificationTitles,
  ...capsDelimitedNotificationTypes,
};

export const ROUTES = {
  dashboard: "/",
  patients: "/patientRecords",
  schedule: "/schedule",
  tasks: "/workQueues",
  clinicalPractitioners: "/clinicalPractitioners",
  adminAppointments: "/adminAppointments",
  shifts: "/shifts",
  users: "/resourceManagement",
  clients: "/clients",
};

export const EMPTY_TEXT = "Not Provided";
export const NONE_SPECIFIED = "None specified";

export const CLIENT_LIVE_ID = 1;
export const CLIENT_DISCARDED_ID = 2;
export const CLIENT_INTERNAL_ID = 3;


export const DEFAULT_EMAIL = "not-specified@example.com";

export const DATE_AND_TIME_FORMAT = "DD/MM/YY, HH:mm";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm"

export const MAX_INT32 = 2_147_483_647;
