import { useState } from "react";
import { useParams } from "react-router-dom";

export default function(tabIndex: number | undefined) {
  const [rendered, setRendered] = useState<boolean>(false);
  const location = useParams();

  if (!rendered && (location['*'] === tabIndex?.toString() || !tabIndex)) {
    setRendered(true);
  }

  return rendered;
}