import { TextInput } from "ra-ui-materialui";
import React, { memo } from "react";
import { ColorInput } from "../../inputs/ColorInput";
import TooltipAdornment from "../../booking/TooltipAdornment";
import { AdornedField } from "../../fields/AdornedField";
import { ImageURLField } from "../../fields/ImageField";
import { FormTab } from "../../Tabs";
import { Heading } from "../../ui/Heading";
import { textInputAdornmentStyles } from "../../../theme";
import { useFormContext } from "react-hook-form";
import { EditTabProps } from "../../../types/utilities";
import useLazyLoadEditTab from "../../../hooks/useLazyLoadEditTab";

export const ContractEditTab = memo(function ContractEditTab(
  props: EditTabProps
) {
  const form = useFormContext();
  const shouldDisplay = useLazyLoadEditTab(props.tabIndex);

  return (
    <FormTab {...props} label="Brand Settings">
      {
        !shouldDisplay ? null :
        <>
          <Heading level={2}>Contract Details</Heading>
          <AdornedField
            Field={TextInput}
            addLabel={false}
            source="contract.attributes.nonGeographicPhoneNumber"
            label="Non Geographic Phone Number"
            adornment={
              <TooltipAdornment
                sx={textInputAdornmentStyles}
                text="This number is currently not patient-facing"
              />
            }
            onBlur={() => {
              form.trigger("contract.attributes.phoneNumber");
            }}
          />

          <AdornedField
            Field={TextInput}
            addLabel={false}
            source="contract.attributes.phoneNumber"
            label="Direct Dial-in Number"
            adornment={
              <TooltipAdornment
                sx={textInputAdornmentStyles}
                text="This number displays in emails as the customer service contact number"
              />
            }
            onBlur={() => {
              form.trigger("contract.attributes.nonGeographicPhoneNumber");
            }}
          />

          <TextInput
            source="contract.attributes.portalCode"
            label="Generic Portal Code"
          />
          <Heading level={3}>Brand Details</Heading>
          <TextInput source="contract.attributes.domain" label="Website Domain" />
          <ColorInput
            source="contract.attributes.clientBrandColourHex"
            label="Brand Color Hex"
          />
          <ImageURLField
            source="contract.attributes.clientBrandLogoUrl"
            label="Brand Logo Url"
          />
        </>
      }
    </FormTab>
  );
});
