import React from "react";
import { useWatch } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { Heading } from "../../ui/Heading";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
} from "@mui/material";
import { ClientEmployerDTO } from "../../../types/clientEmployer";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS, TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";

const MAX_ROW_COUNT = 25;

export function ListNewEmployers() {
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);
  const clientClinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLIENT_CLINICAL_PRODUCTS);

  const newEmployers = useWatch({
    name: "newEmployers",
  }) as ClientEmployerDTO["data"]["attributes"][];

  if (!newEmployers?.length) {
    return null;
  }

  const slice = newEmployers.slice(0, MAX_ROW_COUNT);

  return (
    <div>
      <Heading level={4}>Employers to be added</Heading>
      <Typography variant="body1">
        Showing {slice.length} of {newEmployers.length} rows
      </Typography>

      <TableContainer style={{ listStyle: "none", padding: "0 0 16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employer Name</TableCell>
              {clinicalProductsEnabled || clientClinicalProductsEnabled ? <TableCell>Product Code</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {slice.map(employer => {
              return (
                <TableRow key={employer.name}>
                  <TableCell>{employer.name}</TableCell>
                  {clinicalProductsEnabled || clientClinicalProductsEnabled ? <TableCell>{employer.code}</TableCell> : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
